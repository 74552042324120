<template>
    <!-- start page-wrapper -->
    <div class="page-wrapper" style="overflow: auto;" @scroll="ScrollingHandler">
        
        <!-- start preloader -->
        <!-- <div class="preloader">
            <div class="vertical-centered-box">
                <div class="content">
                    <div class="loader-circle"></div>
                    <div class="loader-line-mask">
                        <div class="loader-line"></div>
                    </div>
                    <img src="./assets-v2/img/isologo azul.png" alt="">
                </div>
            </div>
        </div> -->
        <!-- end preloader -->

        <!-- WHATSAPP BUTTON -->
        <a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios%F0%9F%98%81"
        class="whatsapp" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>

        <!-- Start header -->
        <header id="header">
            <div class="wpo-site-header">
                <nav class="navigation navbar navbar-expand-lg navbar-light"
                    v-bind:class="isSticky ? stickyClass : ''"
                >
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div class="mobail-menu">
                                    <button type="button" class="navbar-toggler open-btn" style="margin-left: 30px;" @click="AbrirMenu()">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar first-angle"></span>
                                        <span class="icon-bar middle-angle"></span>
                                        <span class="icon-bar last-angle"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-6">
                                <div class="navbar-header">
                                    <a class="navbar-brand"><img @click="EnviarAPrincipal()"
                                            src="./assets-v2/img/logo letras negra N azul.png" alt=""></a>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-1 col-1">
                                <div id="navbar" class="collapse navbar-collapse navigation-holder">
                                    <button @click="CerrarMenu()" class="menu-close"><i class="ti-close"></i></button>
                                    <ul class="nav navbar-nav mb-2 mb-lg-0">
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('inicio')">Inicio</a>
                                        </li>
                                       <!--  <li class="menu-item-has-children">
                                            <a href="#">Ejemplos</a>
                                        </li> -->
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('paquetes')">Paquetes</a>
                                        </li>
                                        <li class="menu-item-has-children">
                                            <a @click="scrollTo('disenos')">Diseños</a>
                                        </li>
                                        <!-- <li class="menu-item-has-children">
                                            <a href="#">FAQ</a>
                                        </li> -->
                                        <li class="menu-item-has-children">
                                            <a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios%F0%9F%98%81" target="_blank">Contacto</a>
                                        </li>
                                        <li class="menu-item-has-children" style="padding: 0px 20px;">
                                           <button
                                      class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-2 d-block d-lg-none"
                                      style="background-color: #86a0b6;"
                                      @click="EnviarAlLogin()"
                                    >
                                      Login
                                    </button>
                                        </li>
                                        
                                    </ul>

                                </div><!-- end of nav-collapse -->
                            </div>
                            <div class="col-lg-2 col-md-2 col-2">
                                <div class="header-right">
                                    <button
                                      class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-2 d-none d-lg-block "
                                      style="background-color: #86a0b6;"
                                      @click="EnviarAlLogin()"
                                    >
                                      Login
                                    </button>
                                    <!-- <div class="mini-cart">
                                        <button class="cart-toggle-btn"> <i class="fi flaticon-shopping-cart"></i>
                                            <span class="cart-count">2</span></button>
                                        <div class="mini-cart-content">
                                            <button class="mini-cart-close"><i class="ti-close"></i></button>
                                            <div class="mini-cart-items">
                                                <div class="mini-cart-item clearfix">
                                                    <div class="mini-cart-item-image">
                                                        <a href="shop.html"><img
                                                                src=",/assets/images/shop/mini-cart/img-1.jpg" alt></a>
                                                    </div>
                                                    <div class="mini-cart-item-des">
                                                        <a href="shop.html">Wedding Gown</a>
                                                        <span class="mini-cart-item-price">$20.15 x 1</span>
                                                        <span class="mini-cart-item-quantity"><a href="#"><i
                                                                    class="ti-close"></i></a></span>
                                                    </div>
                                                </div>
                                                <div class="mini-cart-item clearfix">
                                                    <div class="mini-cart-item-image">
                                                        <a href="shop.html"><img
                                                                src="./assets/images/shop/mini-cart/img-2.jpg" alt></a>
                                                    </div>
                                                    <div class="mini-cart-item-des">
                                                        <a href="shop.html">Bridal Flower</a>
                                                        <span class="mini-cart-item-price">$13.25 x 2</span>
                                                        <span class="mini-cart-item-quantity"><a href="#"><i
                                                                    class="ti-close"></i></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mini-cart-action clearfix">
                                                <span class="mini-checkout-price">Subtotal:
                                                    <span>$215.14</span></span>
                                                <div class="mini-btn">
                                                    <a href="checkout.html" class="view-cart-btn s1">Checkout</a>
                                                    <a href="cart.html" class="view-cart-btn">View Cart</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div><!-- end of container -->
                </nav>
            </div>
        </header>
        <!-- end of header -->

        <!-- BANNER SECTION -->
        <section class="wpo-hero-slider wpo-hero-slider-s3" id="BannerCarrousel"  ref="inicio">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="slide-inner slide-bg-image" >
                            <!-- <div class="gradient-overlay"></div> -->
                            <div class="container">
                                <div class="slide-content">
                                    <div data-swiper-parallax="300" class="slide-title">
                                        <h2>Invitaciones digitales</h2>
                                    </div>
                                    <div data-swiper-parallax="400" class="slide-text">
                                        <p>Digitaliza tu evento con las invitaciones más modernas</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div data-swiper-parallax="500" class="slide-btns">
                                        <a @click="scrollTo('disenos')" class="theme-btn-s4">Ejemplos</a>
                                    </div>
                                    <div class="site-border"></div>
                                </div>
                            </div>
                        </div> <!-- end slide-inner -->
                    </div> <!-- end swiper-slide -->

                    <div class="swiper-slide">
                        <div class="slide-inner slide-bg-image" data-background="./assets-v2/img/banner-2.jpg">
                            <!-- <div class="gradient-overlay"></div> -->
                            <div class="container">
                                <div class="slide-content">
                                    <div data-swiper-parallax="300" class="slide-title">
                                        <h2>Para todo tipo de eventos</h2>
                                    </div>
                                    <div data-swiper-parallax="400" class="slide-text">
                                        <p>Toda la información de tu evento en la palma de tu mano</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div data-swiper-parallax="500" class="slide-btns">
                                        <a href="#" class="theme-btn-s4">Ejemplos</a>
                                    </div>
                                    <div class="site-border"></div>
                                </div>
                            </div>
                        </div> <!-- end slide-inner -->
                    </div> <!-- end swiper-slide -->

                    <div class="swiper-slide">
                        <div class="slide-inner slide-bg-image" data-background="./assets-v2/img/banner-3.jpg">
                            <!-- <div class="gradient-overlay"></div> -->
                            <div class="container">
                                <div class="slide-content">
                                    <div data-swiper-parallax="300" class="slide-title">
                                        <h2>Bodas, Bautizos, XV Años y más...</h2>
                                    </div>
                                    <div data-swiper-parallax="400" class="slide-text">
                                        <p>Interactivas, creativas y únicas, las mejores invitaciones para tu evento</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div data-swiper-parallax="500" class="slide-btns">
                                        <a href="#" class="theme-btn-s4">Ejemplos</a>
                                    </div>
                                    <div class="site-border"></div>
                                </div>
                            </div>
                        </div> <!-- end slide-inner -->
                    </div> <!-- end swiper-slide -->
                </div>
                <!-- end swiper-wrapper -->

                <!-- swipper controls -->
                <div class="swiper-pagination"></div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </section>
        <!-- BANNER SECTION END -->

        <!-- INFO SECTION -->
        <section class="wpo-about-section section-padding">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12 col-12">
                        <div class="wpo-about-wrap">
                            <div class="wpo-about-item">
                                <div class="wpo-about-img">
                                    <img src="./assets-v2/img/invitacion-telefono.png" alt="">
                                </div>
                            </div>
                            <div class="about-single-item">
                                <div class="wpo-about-item-s2 wow rollIn" data-wow-duration="2s">
                                    <div class="wpo-about-img">
                                        <img src="./assets-v2/img/invitacion-telefono-2.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-12">
                        <div class="wpo-about-text">

                            <div class="wpo-about-icon-content">
                                <h2>Invitaciones digitales personalizadas</h2>
                                <p>Toda la información de tu evento en la palma de tu mano, ten acceso a tu invitación en cualquier lugar desde tu celular. <br>
                                    Impresiona a todos con los diseños más bonitos y modernos que podrás encontrar, controla y gestiona a tus invitados con nuestro novedoso sistema.
                                    </p>
                                <a class="theme-btn-s3" @click="scrollTo('paquetes')">Paquetes</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- INFO SECTION END -->

         <!-- CONFIGURATION SECTION -->
         <section id="invitation" class="wpo-contact-pg-section  section-padding" >
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title">
                        <span>The Invination Company</span>
                        <h2>¿Cómo creamos tu invitación digital?</h2>
                        <div class="section-title-img">
                            <img src="./assets-v2/img/isologo azul.png" alt="" class="logo-title">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-lg-12">
                        <div class="office-info">
                            <div class="row">
                                <div class="col col-xl-3 col-lg- col-md-6 col-12">
                                    <div class="office-info-item">
                                        <div class="office-info-icon">
                                            <div class="icon">
                                                <i class="fas fa-object-group"></i>
                                            </div>
                                        </div>
                                        <div class="office-info-text">
                                            <h2>Selecciona tu diseño</h2>
                                            <p>Elige el diseño que más te guste para tu invitación</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="office-info-item">
                                        <div class="office-info-icon">
                                            <div class="icon">
                                                <i class="fas fa-check-circle"></i>
                                            </div>
                                        </div>
                                        <div class="office-info-text">
                                            <h2>Elige tu paquete</h2>
                                            <p>Revisa los paquetes que tenemos para ti, elige el que se acople a tu evento</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="office-info-item">
                                        <div class="office-info-icon">
                                            <div class="icon">
                                                <i class="fas fa-edit"></i>
                                            </div>
                                        </div>
                                        <div class="office-info-text">
                                            <h2>Personaliza</h2>
                                            <p>Nuestras invitaciones son 100% personalizadas, puedes editar, agregar y modificar los elementos de tu invitación</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-xl-3 col-lg-6 col-md-6 col-12">
                                    <div class="office-info-item">
                                        <div class="office-info-icon">
                                            <div class="icon">
                                                <i class="fas fa-envelope-open"></i>
                                            </div>
                                        </div>
                                        <div class="office-info-text">
                                            <h2>Invita</h2>
                                            <p>Envíala a todos tus invitados, compártela en todos lados</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </section>
        <!-- CONFIGURATION SECTION END -->

        <!-- start wpo-banner-section -->
        <section class="wpo-banner-section">
            <h4>La invitación digital más linda para tus momentos importantes</h4>
        </section>
        <!-- end wpo-banner-section -->

        <!-- start wpo-blog-section -->
        <section class="wpo-blog-section-s3 section-padding" id="blog" ref="disenos">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title">
                        <h2>Elige tu diseño</h2>
                        <div class="section-title-img">
                            <img src="./assets-v2/img/isologo azul.png" alt="" class="logo-title">
                        </div>
                    </div>
                </div>
                <div class="wpo-blog-items">
                    <div class="row justify-content-center text-center">
                        <div class="col col-lg-4 col-md-6 col-12">
                            <div class="wpo-blog-item">
                                <div class="wpo-blog-img">
                                    <a @click="EnviarAEjemploBoda()" target="_blank"><img src="./assets-v2/img/diseno.png" alt=""></a>
                                </div>
                                <div class="wpo-blog-content">
                                    <a @click="EnviarAEjemploBoda()" target="_blank" class="theme-btn-s4">Ejemplo de Boda</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> <!-- end container -->
        </section>
        <!-- end wpo-blog-section -->

        <!-- start wpo-service-section -->
        <section class="wpo-service-section-s2 section-padding" id="modules">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title">
                        <span>Personaliza tus invitaciones</span>
                        <h2>Elige los módulos</h2>
                        <div class="section-title-img">
                            <img src="./assets-v2/img/isologo azul.png" alt="" class="logo-title">
                        </div>
                    </div>
                </div>
                <div class="wpo-service-wrap">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-calendar-alt"></i>
                                    </div>
                                    <a href="#">Cuenta regresiva</a>
                                    <p>Muéstrale a tus invitados cuanto tiempo falta para tu gran día </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                       <i class="far fa-list-alt"></i>
                                    </div>
                                    <a href="#">Itinerario</a>
                                    <p>Coloca los diferentes momentos que tendrá tu evento, para que tus invitados no se pierdan ninguno de ellos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                       <i class="far fa-star"></i>
                                    </div>
                                    <a href="#">Mesa de regalos</a>
                                    <p>Informa en qué lugares te pueden dejar un pequeño obsequio o tus datos de depósito</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-building"></i>
                                    </div>
                                    <a href="#">Hospedaje</a>
                                    <p>Comparte links, la ubicación, códigos de descuentos o recomendación de hospedaje</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-photo"></i>
                                    </div>
                                    <a href="#">Galeria de fotos</a>
                                    <p>Comparte tus mejores fotos o videos, deja una huella en tus invitados</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-map"></i>
                                    </div>
                                    <a href="#">Ubicaciones</a>
                                    <p>Integra tus ubicaciones más importantes, integradas con Google Maps</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-calendar-check"></i>
                                    </div>
                                    <a href="#">Confirmaciones</a>
                                    <p>Lleva el registro de tus invitados, controla la logística de tu evento</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-12">
                            <div class="wpo-service-item">
                                <div class="wpo-service-text">
                                    <div class="s-icon">
                                        <i class="far fa-envelope-open"></i>
                                    </div>
                                    <a href="#">Pases digitales</a>
                                    <p>Diseñamos tus pases, no te preocupes, son totalmente personalizados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </section>
        <!-- end wpo-service-section-->

        <!-- start wpo-pricing-section -->
        <section class="wpo-pricing-section section-padding " id="package" ref="paquetes">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title">
                        <span>Elige el paquete de tu preferencia</span>
                        <h2>Nuestros paquetes</h2>
                        <div class="section-title-img">
                            <img src="./assets-v2/img/isologo azul.png" alt="" class="logo-title">
                        </div>
                    </div>
                </div>
                <div class="wpo-pricing-wrap">
                    <div class="row justify-content-center">
                        <div class="col col-lg-4 col-md-6 col-12">
                            <div class="wpo-pricing-item">
                                <div class="wpo-pricing-top">
                                    <div class="wpo-pricing-img">
                                        <img src="./assets-v2/img/basic.png" alt="">
                                    </div>
                                    <div class="wpo-pricing-text">
                                        <h4>Standard</h4>
                                        <h2>$1,550.00</h2>
                                    </div>
                                </div>
                                <div class="wpo-pricing-bottom">
                                    <div class="wpo-pricing-bottom-text">
                                        <ul>
                                            <li>Diseño 100% personalizado</li>
                                            <li>Diseño responsivo</li>
                                            <li>Link personalizado con nombre del o los festejados</li>
                                            <li>Un PDF personalizado con link a la invitación digital</li>
                                            <li>Sistema de gestión de invitados, dentro de su portal podrán editar, agregar o eliminar invitados.</li>
                                            <li>Una invitación general del evento</li>
                                        </ul>
                                        <a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20paquete%20Standard" target="_blank">ELEGIR PAQUETE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg-4 col-md-6 col-12">
                            <div class="wpo-pricing-item">
                                <div class="wpo-pricing-top">
                                    <div class="wpo-pricing-img">
                                        <img src="./assets-v2/img/premium.png" alt="">
                                    </div>
                                    <div class="wpo-pricing-text">
                                        <h4>Premium</h4>
                                        <h2>$1,750.00</h2>
                                    </div>
                                </div>
                                <div class="wpo-pricing-bottom">
                                    <div class="wpo-pricing-bottom-text">
                                        <ul>
                                            <li>Diseño 100% personalizado</li>
                                            <li>Diseño responsivo</li>
                                            <li>Link personalizado con nombre del o los festejados</li>
                                            <li>Cantidad de PDF como número de pases sean necesarios, con indicador de pases o boletos</li>
                                            <li>Sistema de gestión de invitados, dentro de su portal podrán editar, agregar o eliminar invitados.</li>
                                            <li>Tres propuestas de diseño para tu PDF.</li>
                                        </ul>
                                        <a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20paquete%20Premium" target="_blank">ELEGIR PAQUETE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg-4 col-md-6 col-12">
                            <div class="wpo-pricing-item">
                                <div class="wpo-pricing-top">
                                    <div class="wpo-pricing-img">
                                        <img src="./assets-v2/img/luxury.png" alt="">
                                    </div>
                                    <div class="wpo-pricing-text">
                                        <h4>Luxary</h4>
                                        <h2>$2,250.00</h2>
                                    </div>
                                </div>
                                <div class="wpo-pricing-bottom">
                                    <div class="wpo-pricing-bottom-text">
                                        <ul>
                                            <li>Diseño 100% personalizado</li>
                                            <li>Diseño responsivo</li>
                                            <li>Link personalizado con nombre del o los festejados</li>
                                            <li>PDF general con información del evento</li>
                                            <li>Botón de whatsapp dentro del panel para compartir link de invitación personalizada</li>
                                            <li>Sistema de gestión de invitados</li>
                                            <li>Una invitación para cada invitado o familia con su nombre</li>
                                        </ul>
                                        <a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20paquete%20Luxury" target="_blank">ELEGIR PAQUETE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- start wpo-pricing-section -->

        <!-- start of wpo-site-footer-section -->
        <footer class="wpo-site-footer" id="footer">
            <div class="wpo-upper-footer">
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-center">
                        <div class="col col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="widget about-widget">
                                <div class="logos">
                                    <img src="./assets-v2/img/logo letras blanca N azul.png" alt="blog">
                                </div>
                            </div>
                        </div>
                        <div class="col col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="widget about-widget">
                                <p>Somos un servicio de invitaciones digitales 100% personalizadas
para todo tipo de eventos, buscamos ser tu herramienta más comoda a la
hora de planear tu evento especial.</p>
                               <!--  <ul>
                                    <li>
                                        <a href="#">
                                            <i class="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="ti-twitter-alt"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="ti-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="ti-google"></i>
                                        </a>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                        <div class="col col-xl-3  col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="widget link-widget">
                                <div class="widget-title">
                                    <h3>INFORMACIÓN</h3>
                                </div>
                                <ul>
                                    <li><a @click="scrollTo('inicio')">Inicio</a></li>
                                    <li><a @click="scrollTo('paquetes')">Paquetes</a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios%F0%9F%98%81" target="_blank">Contacto</a></li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-xl-3  col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="widget wpo-service-link-widget">
                                <div class="widget-title">
                                    <h3>Contacto </h3>
                                </div>
                                <div class="contact-ft">
                                    <ul>
                                        <li><i class="fi flaticon-email"></i><a href="mailto:hola@invination.mx" target="_blank">hola@invination.mx</a></li>
                                        <li><i class="fi flaticon-phone-call"></i><a href="https://api.whatsapp.com/send?phone=524778394460&text=Holaa%2C%20quisiera%20saber%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios%F0%9F%98%81" target="_blank">+52 (477) 8394460</a> </li>
                                        <li>&nbsp;</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- end container -->
            </div>

            <div class="wpo-lower-footer">
                <div class="container">
                    <div class="row">
                        <div class="col col-xs-12">
                            <p class="copyright"> &copy;2022 The Invination Company | Todos los derechos reservados. </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end of wpo-site-footer-section -->

    </div>
        <!-- end wpo-contact-pg-section -->
</template>

<script>
export default {
  data() {
    return {
        isSticky: false,
        stickyClass: 'is_sticky',
    };
  },
  methods: {
    EnviarAPrincipal() {
        this.$router.push({ path: "/" })
    },
    EnviarAlLogin() {
        this.$router.push({ path: "/login" })
    },
    EnviarAEjemploBoda() {
        this.$router.push({ path: "/ejemplo-boda" })
    },
    ScrollingHandler(){
        var bodyRect = document.getElementById('BannerCarrousel').getBoundingClientRect().y
        if(bodyRect < -10){
            this.isSticky = true
        } else {
            this.isSticky = false
        }
    },
    /* eslint-disable */
    scrollTo(param){
        switch(param){
            case 'inicio':
                this.$refs['inicio'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'paquetes':
                this.$refs['paquetes'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
            case 'disenos':
                this.$refs['disenos'].scrollIntoView({ behavior: 'smooth' })
                document.getElementById('navbar').classList.remove('slideInn')
            break;
        }
        
    },
    AbrirMenu() {
        document.getElementById('navbar').classList.add('slideInn')
    },
    CerrarMenu() {
        document.getElementById('navbar').classList.remove('slideInn')
    }
  },
};
</script>

<style>
html{
  overflow-y: visible !important;
}
.navbar.is_sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        width: 100%;
        -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

</style>

<style scoped>
@import './assets/css/themify-icons.css';
@import './assets/css/font-awesome.min.css';
@import './assets/css/flaticon.css';
@import './assets/css/bootstrap.min.css';
@import './assets/css/animate.css';
@import './assets/css/owl.carousel.css';
@import './assets/css/owl.theme.css';
@import './assets/css/slick.css';
@import './assets/css/slick-theme.css';
@import './assets/css/swiper.min.css';
@import './assets/css/nice-select.css';
@import './assets/css/owl.transitions.css';
@import './assets/css/jquery.fancybox.css';
@import './assets/css/odometer-theme-default.css';
@import './assets/css/style.css';
@import './assets/css/themify-icons.css';
@import './assets-v2/css/styledij3.css';
</style>

